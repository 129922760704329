
<template>
  <swiper
    :modules="modules"
    :slides-per-view="2.75"
    :space-between="32"
    :loop="true"
    navigation
    :pagination="{ clickable: true }"
    :scrollbar="{ draggable: true }"
    @swiper="onSwiper"
    @slideChange="onSlideChange"
  >
    <swiper-slide v-for="(item, index) in list" :key="index">
      <img :src="item.pic" alt="photo">
      <p v-html="item.text"></p>
    </swiper-slide>
  </swiper>
</template>

<style lang="scss" scoped>
img{
  width: 100%;
  height: calc(100% - 4rem);
  object-fit: cover;
}
p{
  position: absolute;
  left: 0;
  bottom: -.1rem;
  width: 100%;
  background: $white;
  padding: 1.2rem 0;
  text-align: center;
}
</style>

<script>
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  props: {
    list: {
      type: Array,
      required: true
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const onSwiper = (swiper) => {
    }
    const onSlideChange = () => {
    }
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    }
  },
}
</script>
