// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7139e23c]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7139e23c]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7139e23c]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7139e23c]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
footer[data-v-7139e23c] {
  background: rgb(231.3428571429, 237.2571428571, 233.3142857143);
  color: #202b2b;
}
footer a[data-v-7139e23c] {
  color: #202b2b;
}
footer .pagetop[data-v-7139e23c] {
  background: rgb(237.1714285714, 241.6285714286, 238.6571428571);
  display: block;
  text-align: center;
  padding: 1rem 0;
}
footer .max[data-v-7139e23c] {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: calc(calc(var(--size) + 2vw) * 1.66);
  padding: 4.8rem calc(var(--size) + 2vw);
}
footer .company[data-v-7139e23c] {
  display: grid;
  grid-template-columns: 10.6rem 1fr;
  grid-gap: 2rem;
}
footer .company figure img[data-v-7139e23c] {
  opacity: 0.8;
  width: 100%;
  object-fit: cover;
}
footer .company dl[data-v-7139e23c] {
  line-height: 1.56;
}
footer .company dl dd[data-v-7139e23c] {
  font-size: 0.72rem;
  margin: 0.4rem 0 0;
}
footer nav ul[data-v-7139e23c] {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 0.4rem;
}
footer nav ul li a[data-v-7139e23c] {
  font-size: 0.92rem;
}
footer nav ul li a[data-v-7139e23c]:hover {
  text-decoration: underline;
}
footer small[data-v-7139e23c] {
  display: block;
  text-align: center;
  font-size: 0.72rem;
  border-top: rgba(32, 43, 43, 0.08) 0.1rem solid;
  padding: 2.4rem 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./footers.vue","webpack://./src/js/components/footers.vue"],"names":[],"mappings":"AAEA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKC,OAAA;EACD,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;AD0HA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACvHD;AC7DA;EACE,+DAAA;EACA,cAAA;AD+DF;AC9DE;EACE,cFFK;ACkET;AC9DE;EACE,+DAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;ADgEJ;AC9DE;EACE,aAAA;EACA,+BAAA;EACA,8CAAA;EACA,uCAAA;ADgEJ;AC9DE;EACE,aAAA;EACA,kCAAA;EACA,cAAA;ADgEJ;AC9DM;EACE,YAAA;EACA,WAAA;EACA,iBAAA;ADgER;AC7DI;EACE,iBAAA;AD+DN;AC9DM;EACE,kBAAA;EACA,kBAAA;ADgER;AC3DI;EACE,aAAA;EACA,qCAAA;EACA,qBAAA;EACA,oBAAA;AD6DN;AC3DQ;EACE,kBAAA;AD6DV;AC5DU;EACE,0BAAA;AD8DZ;ACxDE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,+CAAA;EACA,iBAAA;AD0DJ","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
