// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&family=Roboto:wght@400;700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* BASE COLOR
------------------------------------------------------------*/
/* DISPLAY SETTING
------------------------------------------------------------*/
/* OTHER SETTING
------------------------------------------------------------*/
[data-v-7653fc8a]:root {
  font-size: clamp(13px + 2 * (100vw - 1440px) / 480, 1vw, 16px + 2 * (100vw - 1440px) / 480);
  --cx: 0;
  --cy: 0;
  --vw: 100vw;
  --vh: 100vh;
  --screen: 0px;
  --size: 32px;
}
@media screen and (min-width: 1920px) {
[data-v-7653fc8a]:root {
    font-size: 16px;
}
}
@media screen and (max-width: 1023px) {
[data-v-7653fc8a]:root {
    font-size: 14px;
    --size: 16px;
}
}
@media screen and (max-width: 769px) {
[data-v-7653fc8a]:root {
    font-size: 13.5px;
    --size: 10px;
}
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 100;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 200;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 300;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 400;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: 500;
}
@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}
header[data-v-7653fc8a] {
  width: 100%;
  position: absolute;
  padding: 0.8rem calc(var(--size) + 2vw);
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
}
header.active[data-v-7653fc8a] {
  position: fixed;
  background: #f9fefb;
  padding: 0.64rem calc(var(--size) + 2vw);
  transform: translateY(-100%);
  animation: viewHeader-7653fc8a 400ms cubic-bezier(0.65, 0, 0.35, 1) forwards;
}
@keyframes viewHeader-7653fc8a {
0% {
    transform: translateY(-100%);
}
100% {
    transform: translateY(0);
}
}
header.active .logo[data-v-7653fc8a] {
  width: 9rem;
  transform: translateY(0.72rem) scale(1.2);
  animation: logoScale-7653fc8a 400ms 200ms cubic-bezier(0.65, 0, 0.35, 1) forwards;
}
@keyframes logoScale-7653fc8a {
0% {
    transform: translateY(0.72rem) scale(1);
}
100% {
    transform: translateY(0.72rem) scale(1.2);
}
}
header.active nav[data-v-7653fc8a] {
  left: 50%;
  transform: translate(-50%, -50%);
}
header[data-v-7653fc8a] > * {
  align-self: center;
}
header .logo[data-v-7653fc8a] {
  width: 10rem;
  justify-self: flex-start;
  transform: translateY(0.8rem) scale(1.1);
}
header .logo img[data-v-7653fc8a] {
  width: 100%;
}
header nav[data-v-7653fc8a] {
  position: absolute;
  top: 50%;
  left: 18rem;
  transform: translateY(-50%);
}
header nav ul[data-v-7653fc8a] {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}
header nav ul li[data-v-7653fc8a] {
  margin: 0 0 0 2.8rem;
  font-weight: 500;
}
header nav ul li a[data-v-7653fc8a]:hover {
  color: #00a199;
}
header nav ul li[data-v-7653fc8a]:first-child {
  margin: 0;
}
header .btn[data-v-7653fc8a] {
  justify-self: flex-end;
  color: #f9fefb;
  border: #00a199 1px solid;
  font-size: 1.08rem;
  line-height: 1.5;
  padding: 0.8rem 3.2rem;
  border-radius: 999rem;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1;
  transition: color 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn[data-v-7653fc8a]:before {
  content: "";
  background: #00a199;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: width 320ms cubic-bezier(0.65, 0, 0.35, 1);
}
header .btn svg[data-v-7653fc8a] {
  position: relative;
  display: inline-block;
  width: 1.4rem;
  transform: translateX(0.64rem);
  fill: #f9fefb;
  height: auto;
}
header .btn[data-v-7653fc8a]:hover {
  color: #00a199;
}
header .btn:hover svg[data-v-7653fc8a] {
  fill: #00a199;
}
header .btn[data-v-7653fc8a]:hover:before {
  left: auto;
  right: 0;
  width: 0;
}`, "",{"version":3,"sources":["webpack://./src/scss/module/_module.scss","webpack://./headers.vue","webpack://./src/js/components/headers.vue"],"names":[],"mappings":"AAEA;6DAAA;AAeA;6DAAA;AAOA;6DAAA;AAEA;EACC,2FAAA;EAKC,OAAA;EACD,OAAA;EACA,WAAA;EACA,WAAA;EACC,aAAA;EACA,YAAA;ACtBF;ADuBC;AAZD;IAaE,eAAA;ACpBA;AACF;ADqBC;AAfD;IAgBE,eAAA;IACE,YAAA;AClBF;AACF;ADmBC;AAnBD;IAoBE,iBAAA;IACE,YAAA;AChBF;AACF;AD0HA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,8BAAA;EACA,gBAAA;ACvHD;ADyHA;EACC,wBAAA;EACA,4BAAA;EACA,iBAAA;ACvHD;AC7DA;EACE,WAAA;EACA,kBAAA;EACA,uCAAA;EACA,aAAA;EACA,gCAAA;EACA,aAAA;AD+DF;AC9DE;EACE,eAAA;EACA,mBFJI;EEKJ,wCAAA;EACA,4BAAA;EACA,4EAAA;ADgEJ;AC/DI;AACE;IACE,4BAAA;ADiEN;AC/DI;IACE,wBAAA;ADiEN;AACF;AC/DI;EACE,WAAA;EACA,yCAAA;EACA,iFAAA;ADiEN;AChEM;AACE;IACE,uCAAA;ADkER;AChEM;IACE,yCAAA;ADkER;AACF;AC/DI;EACE,SAAA;EACA,gCAAA;ADiEN;AC9DE;EACE,kBAAA;ADgEJ;AC9DE;EACE,YAAA;EACA,wBAAA;EACA,wCAAA;ADgEJ;AC/DI;EACE,WAAA;ADiEN;AC9DE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;ADgEJ;AC/DI;EFLH,aAAA;EACA,mBAAA;EACA,8BAHiC;EAIhC,iBAAA;EEII,uBAAA;EACA,mBAAA;ADoEN;ACnEM;EACE,oBAAA;EACA,gBAAA;ADqER;ACnEU;EACE,cFvDL;AC4HP;AClEQ;EACE,SAAA;ADoEV;AC/DE;EACE,sBAAA;EF+BF,cApGM;EAqGN,yBAAA;EACA,kBAAA;EACA,gBAAA;EACA,sBALiD;EAMjD,qBAAA;EACA,gBAAA;EACA,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,sDAAA;ACmCF;ADlCE;EACE,WAAA;EACA,mBAjHG;EAkHH,WAAA;EACA,YAAA;EACA,kBAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,sDAAA;ACoCJ;ADlCE;EACE,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,8BAAA;EACA,aAnII;EAoIJ,YAAA;ACoCJ;ADlCE;EACE,cAnIG;ACuKP;ADnCI;EACE,aArIC;AC0KP;ADnCI;EACE,UAAA;EACA,QAAA;EACA,QAAA;ACqCN","sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
