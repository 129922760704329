<template>
	<footer id="site-footer" class="in-module" role="contentinfo" itemscope="itemscope" itemtype="http://schema.org/WPFooter">
    <a href="#app" class="pagetop">▲ ページの先頭へ</a>
    <div class="max">
      <div class="company">
        <figure><img src="/images/common/logo.webp" alt="株式会社ゼンケイ"></figure>
        <dl>
          <dt>株式会社ゼンケイ</dt>
          <dd>〒151-0053 東京都渋谷区代々木2丁目5-5 新宿農協会館3階<br>TEL.03-3320-0086</dd>
        </dl>
      </div>
      <nav>
        <ul>
          <li itemprop="name"><a itemprop="url" href="/">インデックス</a></li>
          <li itemprop="name"><a itemprop="url" href="/about/">ゼンケイについて</a></li>
          <li itemprop="name"><a itemprop="url" href="/business/">ご提供するもの</a></li>
          <li itemprop="name"><a itemprop="url" href="/items/">商品紹介</a></li>
          <li itemprop="name"><a itemprop="url" href="/company/">会社概要</a></li>
          <li itemprop="name"><a itemprop="url" href="/contact/">お問い合わせ</a></li>
          <li itemprop="name"><a itemprop="url" href="/news/">お知らせ</a></li>
          <li itemprop="name"><a itemprop="url" href="/privacy/">個人情報保護方針</a></li>
        </ul>
      </nav>
    </div>
    <small class="copy">Copyright &copy; {{ currentYear }} Zenkei All rights reserved.</small>
	</footer>
</template>

<style lang="scss" scoped>
footer{
  background: darken($gray, 4%);
  color: $normal;
  a{
    color: $normal;
  }
  .pagetop{
    background: darken($gray, 2%);
    display: block;
    text-align: center;
    padding: 1rem 0;
  }
  .max{
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: side2x();
    padding: 4.8rem side();
  }
  .company{
    display: grid;
    grid-template-columns: 10.6rem 1fr;
    grid-gap: 2rem;
    figure{
      img{
        opacity: .8;
        width: 100%;
        object-fit: cover;
      }
    }
    dl{
      line-height: 1.56;
      dd{
        font-size: .72rem;
        margin: .4rem 0 0;
      }
    }
  }
  nav{
    ul{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: .4rem;
      li{
        a{
          font-size: .92rem;
          &:hover{
            text-decoration: underline;
          }
        }
      }
    }
  }
  small{
    display: block;
    text-align: center;
    font-size: .72rem;
    border-top: rgba($normal,.08) .1rem solid;
    padding: 2.4rem 0;
  }
}
</style>

<script>
export default {
	data() {
		return {
			currentYear: new Date().getFullYear()
		}
	}
}
</script>