<template>
  <header id="site-header">
    <figure class="logo">
      <a href="/">
        <img src="/images/common/logo.webp" alt="株式会社ゼンケイ" />
      </a>
    </figure>
    <nav class="gnavi" role="navigation" itemscope="itemscope" itemtype="http://scheme.org/SiteNavigationElement">
      <ul>
        <li itemprop="name"><a itemprop="url" href="/about/">ゼンケイについて</a></li>
        <li itemprop="name"><a itemprop="url" href="/business/">ゼンケイがご提供するもの</a></li>
        <li itemprop="name"><a itemprop="url" href="/items/">商品紹介</a></li>
        <li itemprop="name"><a itemprop="url" href="/company/">会社概要</a></li>
      </ul>
    </nav>
    <a href="/contact/" class="btn">お問い合わせ</a>
  </header>
</template>

<style lang="scss" scoped>
header{
  width: 100%;
  position: absolute;
  padding: .8rem side();
  display: grid;
  grid-template-columns: auto auto;
  z-index: 1000;
  &.active{
    position: fixed;
    background: $white;
    padding: .64rem side();
    transform: translateY(-100%);
    animation: viewHeader 400ms $ease forwards;
    @keyframes viewHeader {
      0%{
        transform: translateY(-100%);
      }
      100%{
        transform: translateY(0);
      }
    }
    .logo{
      width: 9rem;
      transform: translateY(.72rem) scale(1.2);
      animation: logoScale 400ms 200ms $ease forwards;
      @keyframes logoScale {
        0%{
          transform: translateY(.72rem) scale(1);
        }
        100%{
          transform: translateY(.72rem) scale(1.2);
        }
      }
    }
    nav{
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  > *{
    align-self: center;
  }
  .logo{
    width: 10rem;
    justify-self: flex-start;
    transform: translateY(.8rem) scale(1.1);
    img{
      width: 100%;
    }
  }
  nav{
    position: absolute;
    top: 50%;
    left: 18rem;
    transform: translateY(-50%);
    ul{
      @include flex();
      justify-content: center;
      align-items: center;
      li{
        margin: 0 0 0 2.8rem;
        font-weight: 500;
        a{
          &:hover{
            color: $base;
          }
        }
        &:first-child{
          margin: 0;
        }
      }
    }
  }
  .btn{
    justify-self: flex-end;
    @include btn($white, $base);
  }
}
</style>

<script>
export default {
}
</script>
