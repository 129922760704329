import * as THREE from 'three'
import fragmentShader from './glsl/fragmentShader.frag'
import vertexShader from './glsl/vertexShader.vert'
import gsap from 'gsap'
import { TweenMax, Power2 } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import * as SmoothScroll from 'smooth-scroll'

gsap.registerPlugin(ScrollTrigger)

export default function background(){
  
  const app = document.getElementById('backgroundCanvas')
  if(app){
    const scene = new THREE.Scene()
    let timer = 0
    
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    camera.position.z = 10
    camera.position.x = -5

    const renderer = new THREE.WebGLRenderer({alpha: true})
    renderer.setPixelRatio(window.devicePixelRatio)
    renderer.setSize(window.innerWidth, window.innerHeight)
    app.appendChild(renderer.domElement)
    
    const geometry = new THREE.SphereGeometry(1, 256, 256)    
    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      //wireframe: true,
      uniforms: {
        time: { value: 0 },
        frequency: { value: 6 },
        amplitude: { value: .5 },
        edge: { value: 1 }
      }
    })

    
    // メッシュの生成
    const sphere = new THREE.Mesh(geometry, material)
    scene.add(sphere)

    const clock = new THREE.Clock()
    window.addEventListener('resize', onWindowResize)
    animate()

    function animate() {
      timer += .005
      firstAnimate(timer)
      requestAnimationFrame(animate)
      renderer.render(scene, camera)
    }

    function firstAnimate(timer){
      material.uniforms.time.value = clock.getElapsedTime()
      material.uniforms.amplitude.value += Math.sin(timer) * .0125
      sphere.rotation.z += .005
      sphere.rotation.x += .005
      geometry.attributes.position.needsUpdate = true
    }

    function onWindowResize() {
      const newWidth = window.innerWidth
      const newHeight = window.innerHeight
      camera.aspect = newWidth / newHeight
      camera.updateProjectionMatrix()
      renderer.setSize(newWidth, newHeight)
    }
    
    gsap.fromTo(camera.position,
      {},
      {
        z: 15,
        x: 0,
        scrollTrigger: {
          trigger: '#app',
          start: "top top",
          end: "bottom bottom",
          scrub: 3,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
    gsap.fromTo(material.uniforms.edge,
      {},
      {
        value : 1.5,
        scrollTrigger: {
          trigger: '#app',
          start: "top top",
          end: "bottom bottom",
          scrub: 0,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )
    gsap.fromTo(material.uniforms.frequency,
      {},
      {
        value : 8.5,
        scrollTrigger: {
          trigger: '#app',
          start: "top top",
          end: "bottom bottom",
          scrub: 0,
          once: false,
          //markers: true,
          invalidateOnRefresh: true,
        }
      }
    )


  }
}